
import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';

import './App.css';

function App() {
  const [open, setOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState('');
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('message', function(event) {
      if (event.data && event.data.type === 'yuncezhihui-load-landing-page') {
        setDrawerContent(event.data.content);
        showDrawer();
      }
    });
  }, []);

  return (
    <div className="App">
      <img src="/fake-yongyou.png" />
      <iframe
        className="cta"
        src="https://embedded.yuncezhihui.com/?clientId=23&token=wbg93h94h3u8g&hmac=061f3f4cd5571058e5dbcc48e0377abb72886266"
      />
      <Drawer onClose={onClose} open={open} >
        <div style={{ margin: -24 }} dangerouslySetInnerHTML={{ __html: drawerContent }}></div>
      </Drawer>
    </div>
  );
}

export default App;
